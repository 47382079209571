$z-index-bg: 0;

$z-index-3D: 1;
$z-index-wrap: 2;
$z-index-title: 3;
$z-index-cookies: 4;
$z-index-goto-main: 5;
$z-index-progress: 6;
$z-index-sidemenu: 9;
$z-index-header: 10;
$z-index-footer: 10;
$z-index-scrollbar: 11;

$z-index-cursor: 10;
$z-index-interface: 11;
$z-index-windows: 12;
$z-index-loader: 13;

$z-index-reserva: 6;

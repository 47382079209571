#Sidemenu {
    --bg: var(--green-light);
    --color: var(--black);
    --font-size: var(--font-size-m);
    --font-size-label: var(--font-size-s);
    --font-size-footer: var(--font-size-xxs);
    --padding-top: calc(var(--header-height) + var(--padding-medium));
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-xsmall);
}

#Sidemenu {
    background-color: var(--bg);
    color: var(--color);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;

    @include z-index($z-index-sidemenu);

    font-size: var(--font-size);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    & > .content {
        height: calc(var(--vh, 1vh) * 100);
        left: 0;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        @include z-index(1);
    }

    .nav-item {
        @include basic-a();
        @include font-serif-light();

        color: currentColor;
        display: block;
        overflow: hidden;

        &[aria-current="page"] {
            pointer-events: none;

            .__text::after {
                content: '';
                display: inline-block;
                position: relative;
                pointer-events: none;
                width: .25em;
                height: .25em;
                top: -.1em;
                background-color: currentColor;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
        }

        .__text {
            display: block;
            padding: var(--padding-xsmall) 0;
            position: relative;
        }
    }

    .sublink {
        padding: var(--padding-xsmall) 0;

        .label,
        li:not(:last-child) { margin-bottom: var(--padding-xsmall); }

        .label {
            @include font-sans(1);
            font-size: var(--font-size-label);
        }

        .nav-item .__text {
            padding: var(--padding-xs) 0;
        }
    }

    .list li {
        position: relative;

        .separator {
            position: absolute;
            left: 0;
            width: 100%;
            transform-origin: 0 0;
            border-bottom: 1px solid currentColor;

            &:first-child { top: 0 }
            &:last-child { bottom: 0; }
        }
    }

    .footer {
        @include font-sans(1);
        font-size: var(--font-size-footer);
        margin-top: var(--padding-xxl);
        text-align: center;
    }
}
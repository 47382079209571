.block-image {
    --grid-col: 1 / 15;
    
    @media (max-width: $smartphone) {
        --grid-col: 1 / 15;
    }
}

.block-image {    
    display: grid;
    margin: var(--padding-xs) 0;
    grid-template-columns: calc(var(--padding-general) - var(--gap)) repeat(12, 1fr) calc(var(--padding-general) - var(--gap));
    
    > * {
        grid-column: var(--grid-col);
    }

    .media-holder > img {
        height: 120%;
        object-fit: cover;
        object-position: center;
    }
}
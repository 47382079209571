@mixin body-default() {
    --font-size-h1: var(--font-size-xlarge);
    --font-size-h2: var(--font-size-large);
    --font-size-h3: var(--font-size-mid);
    --font-size: var(--font-size-base);
    
    @media (max-width: $smartphone) {   
        --font-size: var(--font-size-xxsmall);
    }

    color: currentColor;

    @include font-sans-light();
    font-size: var(--font-size);
   
    h1 {
        @include font-sans(1);
        font-size: var(--font-size-h1);
        margin: .5em 0;
    }

    h2 {
        @include font-sans(1);
        font-size: var(--font-size-h2);
        margin: .5em 0;
    }

    h3 {
        @include font-sans(1);
        font-size: var(--font-size-h3);
        margin: .5em 0;
    }

    svg {
        max-width: 100px;
        height: auto;
    }

    p {
        i {
            @include font-sans();
        }

        strong,
        b {
            @include font-sans();
        }
        
        &:not(:first-child):not(:last-child) {
            margin: .7em 0;
        }

        &:first-child:not(:last-child) {
            margin: 0 0 .7em;
        }

        &:last-child:not(:first-child) {
            margin: .7em 0 0;
        }
    }

    ul, ol {
        margin: 1em 0;
        padding-left: 4em;

        > li {
            margin: 1em;
        }
    }

    a {
        @include basic-a();
        @include font-sans();
        text-decoration: underline;
        color: inherit;
    }
}

.body-default {
    @include body-default();
}


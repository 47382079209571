.block-inspo {
    --font-size-title: var(--font-size-gigant);
    --padding-top: calc(var(--font-size-title) * .5);

    @media (max-width:$smartphone) {
        --font-size-title: 42vw;
    } 
}

.block-inspo {
    position: relative;
    padding-top: var(--padding-top);

    .headline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--font-size-title);
        > span {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            white-space: nowrap;
            margin: 0;

            @include font-serif-light(1);
            font-size: var(--font-size-title);
            letter-spacing: var(--letter-spacing-serif);
            text-transform: uppercase;
            padding: 0;
        }
    }

    figure.media-holder {
        padding: 0;
        height: calc(var(--font-size-title) * 2);
    }

    figure.media-holder img {
        height: 130%;
        object-fit: cover;
        object-position: center;
    }
}

.block-inspo__controls {
    height: var(--padding-top);
    display: flex;

    > button {
        --bg:#fff;
        @include basic-a();
        border: 0;
        flex: 1 0 auto;
        padding: 0;
        background-color: var(--bg);
    }
}
.widget-contact {
    --font-size-label: var(--font-size-s);
    --font-size-content: var(--font-size-l);
    --padding-section: var(--padding-general);

    --size-date: #{(60px / 16px) * 1rem};
    --max-width-body: #{(200px / 16px) * 1rem};
  
    @media (max-width: $smartphone) {
        --font-size-label: var(--font-size-xs);
        --font-size-content: var(--font-size-m);
        --padding-section: 0;
    }
}

.widget-contact {
    margin: var(--margin-section) 0 var(--padding-xl);
    padding: 0 var(--padding-general);
}

.block-images-column {
    --left-row: 1 / 4;
    --center-row: 2 / 3;
    --right-row: 1 / 4;

    &:nth-child(odd) {
        --left-col: 1 / 7;
        --center-col: 6 / 8;
        --right-col: 8 / 13;
    }

    &:nth-child(even) {
        --left-col: 1 / 6;
        --center-col: 5 / 7;
        --right-col: 7 / 13;
    }

    &.--equals {
        --left-col: 1 / 5;
        --center-col: 5 / 9;
        --right-col: 9 / 13;

        --left-row: 1 / 2;
        --center-row: 1 / 2;
        --right-row: 1 / 2;
    }

    @media (max-width:$smartphone) {
        &:nth-child(odd),
        &:nth-child(even) {
            --left-col: 1 / 13;
            --center-col: 1 / 13;
            --right-col: 1 / 13;
        }

        --left-row: 1 / 2;
        --center-row: 3 / 4;
        --right-row: 2 / 3;
    }
}

.block-images-column {
    display: grid;
    padding: 0 var(--padding-general);
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap);
    margin: var(--margin-section) 0;

    > .left {
        grid-column: var(--left-col);
        grid-row: var(--left-row);
    }

    > .center {
        grid-column: var(--center-col);
        grid-row: var(--center-row);
    }

    > .right {
        grid-column: var(--right-col);
        grid-row: var(--right-row);
    }

    figure.media-holder img {
        height: 120%;
        object-fit: cover;
        object-position: center;
    }
}

    
.title-viewer {
    --font-size: var(--font-size-gigant);
    --color: var(--color-primary);

    position: fixed;
    @include z-index($z-index-title);
    top:0;
    left:0;
    width: 100%;
    height: 100%;

    pointer-events: none;

    > .text {
        position: absolute;
        bottom: -.25em;
        left:0;
        display: block;
        white-space: nowrap;
        margin: 0;
        height: 1em;

        @include font-serif-light(1);
        font-size: var(--font-size);
        letter-spacing: var(--letter-spacing-serif);
        text-transform: uppercase;
        padding: 0 calc(var(--padding-general) - .04em);
    }
}
    



#Cookies {
  --color: var(--primary-color);
  --bg: var(--primary-bg);
  --padding-v: var(--padding-xs);
  --padding-h: var(--padding-xs);
  --font-size-big: var(--font-size-xs);
  --font-size: var(--font-size-xs);

  --margin: var(--padding-xs);
  --max-width: #{(360px / 16px) * 1rem};
  --total: calc(100% - var(--margin) - var(--margin));

  &.__success {
    --bg: var(--color-sucess);
    --color: var(--black);
  }

  &.__error {
    --bg: var(--color-error);
    --color: var(--white);
  }

  &[aria-expanded='false'] {
    visibility: hidden;
    pointer-events: none;
  }

  @media (max-width: $smartphone) {
    --font-size-big: var(--font-size-xs);
    --font-size: var(--font-size-xs);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --margin: var(--padding-xxs);
    --max-width: 100%;
  }
}


#Cookies {
  position: fixed;
  left: var(--margin);
  bottom: var(--margin);
  width: min(var(--max-width), var(--total));

  @include z-index($z-index-windows);
  
  display: block;
  padding: var(--padding-v) var(--padding-h);
    
  border: 1px solid var(--color);
  color: var(--color);
  backdrop-filter: blur(8px);
  
  .content {
    display: block;
    position: relative;
  }

  .controls {
    display: flex;
    justify-content: flex-end;
  }
}

//TEXTO
#Cookies p {
  @include font-sans-light();
  font-size: var(--font-size);
  margin: 0 0 1em;
  padding-bottom: 0;
  
  b, strong {
    @include font-sans();
  }

  a {
    @include basic-a();
    color: inherit;
    &:after {
      content: '↘'
    }
  }
}


///BOTON
#Cookies button {
  @include basic-a();
  @include font-sans-light();
  line-height: 1;
  font-size: var(--font-size-big);
  border: 1px solid var(--color);
  background: transparent;
  text-transform: uppercase;
  padding: var(--padding-xs) var(--padding-xsmall);

  &:first-of-type {
    border-right: 0;
  }

  &:hover {
    background-color: var(--color);
    color: var(--bg);
  }
}

@media (max-width: $smartphone) {
  #Cookies {
    background-color: rgba($color: #000000, $alpha: .1);

    .content {
      width: 100%;
    }

    .controls {
      width: 100%;
      min-width: 100%;
      padding: 0;
      display: flex;
    }

    .btn-ok {
      width: 100%;
    }
  }
}

:root {
  --y-header: 0;

  --gap: #{(12px / 16px) * 1rem};
  --padding-general: #{(30px / 16px) * 1rem};

  --padding-xxxxl: #{(150px / 16px) * 1rem};
  --padding-xxxl: #{(100px / 16px) * 1rem};
  --padding-xxl: #{(80px / 16px) * 1rem};
  --padding-xl: #{(60px / 16px) * 1rem};
  --padding-l: #{(48px / 16px) * 1rem};
  --padding-m: #{(36px / 16px) * 1rem};
  --padding-medium: #{(30px / 16px) * 1rem};
  --padding-s: #{(24px / 16px) * 1rem};
  --padding-xsmall: #{(18px / 16px) * 1rem};
  --padding-xs: #{(12px / 16px) * 1rem};
  --padding-xxs: #{(6px / 16px) * 1rem};

  --header-height:#{(84px / 16px) * 1rem};

  --margin-section: #{(120px / 16px) * 1rem};
  --cols: 12;
  --col-size: calc((100vw - var(--padding-general) * 2)/var(--cols))
}

@media (max-width: $smartphone) {
  :root {
    --padding-general: #{(15px / 16px) * 1rem};
    --margin-section: #{(60px / 16px) * 1rem};
  }
}

.block-cabecera-servicios {
    --font-size-h1: var(--font-size-gigantsm);
    --font-size-h2: var(--font-size-m);
    --font-size-large: var(--font-size-xl);
    --font-size-small: var(--font-size-base);
    --color: var(--primary-color);

    @media (max-width:$smartphone) {
        --font-size-h1: 19vw;
        --font-size-h2: var(--font-size-s);
        --font-size-large: var(--font-size-m);
        --font-size-small: var(--font-size-s);
    }
}

.block-cabecera-servicios {
    h1 {
        margin-bottom: var(--padding-xxxl);

        .headline {
            @include font-serif-light(1);

            position: relative;
            text-align: center;
            font-size: var(--font-size-h1);
            text-transform: uppercase;
            letter-spacing: var(--letter-spacing-serif);
            margin-top: -.1em;
            perspective: 1500px;
        }

        .name {
            @include font-sans-light();
            
            font-size: var(--font-size-h2);
            text-transform: uppercase;
            padding: 0 var(--padding-general);
        }
    }
    
    .text {
        padding: 0 var(--padding-general);
        margin-bottom: var(--padding-l);
        
        .div-line { overflow: hidden; }
    
        .large-text {
            @include font-serif-light(1);
    
            font-size: var(--font-size-large);
            text-transform: uppercase;
        }

        .small-text {
            @include font-sans-light();
    
            align-self: flex-end;
            display: flex;
            font-size: var(--font-size-small);
        }
    }

    .image {
        overflow: hidden;
    }

    @media (max-width:$smartphone) {
        h1 {
            margin-bottom: var(--padding-m);
        }

        .text {
            margin-bottom: var(--padding-s);
        }

        .small-text {
            margin-top: var(--padding-s);
            padding-left: var(--padding-xl);
        }
    }

    @media (min-width:$smartphone) {
        .text {
            display: flex;
            min-height: rem(500);
        }

        .large-text {
            margin-right: var(--padding-xl);
            min-width: 55%;
            width: 55%;
        }
    }
}
.post {
    --font-size-title: var(--font-size-m);
    --font-size-label: var(--font-size-base);
    --font-size-text: var(--font-size-s);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-m);
        --font-size-label: var(--font-size-base);
        --font-size-text: var(--font-size-s);
    }
}

.post {
    @include basic-a();
    color: currentColor;
    border-top: 1px solid var(--primary-color);
    padding: var(--padding-xl) 0 var(--padding-xs);

    &__img {
        padding: 0 var(--padding-xs);

        &:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }
    
    &__title {
        @include font-serif();
        font-size: var(--font-size-title);
        
        &:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }
    
    &__text {
        @include font-sans-light();
        font-size: var(--font-size-label);
        
        &:not(:last-child) {
            margin-bottom: var(--padding-medium);
        }
    }
    
    &__info {
        @include font-sans();
        font-size: var(--font-size-text);
        text-transform: uppercase;

        p:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
    }

    @media (max-width: $smartphone) {
        li:not(:last-child) & {
            padding-bottom: var(--padding-l);
        }
    }
}
#Header {
    --width: 100%;
    --widthLogo: #{(60px / 16px) * 1rem};
    --font-size: var(--font-size-xs);
    --font-size-subnav: var(--font-size-xxs);
    --color: var(--primary-color);
    --padding-v: var(--padding-general);
    --padding-h: var(--padding-general);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xs);
    }

    &.nav-open {
        --color: var(--black);
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    left: 0;
    top: 0;
    margin-top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    color: var(--color);

    transform: translate3d(0, var(--y-header), 10px);
    transition: margin .4s ease-in-out;
}

#Header > .toggle-menu {
    background-color: transparent;
    height: rem(30);
    padding: 0;
    transition: transform .8s ease-out;
    width: rem(30);

    svg {
        pointer-events: none;

        line {
            stroke: currentColor;
        }
    }

    @media (min-width: $smartphone) {
        display: none;
    }

    &.__close {
        transform: rotate(270deg);
    }
}

#Header > .logo {
    @include basic-a();
    width: var(--widthLogo);
    height: auto;
    color: var(--color);

    > svg {
        fill: currentColor;
    }
}

#Header > nav {
    display: flex;

    @media (max-width: $smartphone) {
        display: none;
    }

    .nav-item {
        @include basic-a();
        @include font-sans(1);

        position: relative;
        font-size: var(--font-size);
        text-transform: uppercase;
        color: inherit;
        cursor: pointer;
        margin: 0 0 0 1em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &[aria-current="page"] {
            pointer-events: none;

            > span {
                opacity: 1;
            }
        }

        > span {
            position: relative;
            pointer-events: none;
            opacity: 0;
            width: .6em;
            height: .6em;
            margin-top: .2em;
            background-color: currentColor;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }
    }

    .sublink {
        margin: 0 0 0 1em;
        position: relative;

        .nav-item { margin: 0; }

        ul {
            display: none;
            padding-top: var(--padding-xxs);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            li {
                margin: 0 var(--padding-xxs);
            }
        }

        &:hover ul { display: flex; }
    }
}

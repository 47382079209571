#Footer {
    --font-size-label: var(--font-size-s);
    --font-size-content: var(--font-size-l);
    --font-size-legal: var(--font-size-xs);
    --padding-section: var(--padding-general);
    --max-width-body: #{(240px / 16px) * 1rem};
    @media (max-width: $smartphone) {
        --font-size-label: var(--font-size-xs);
        --font-size-content: var(--font-size-m);
        --font-size-legal: var(--font-size-xxs);
        --padding-section: 0;
    }
}

#Footer {
    padding: 0 var(--padding-general) var(--padding-s);
    color: var(--primary-color);
    .separator {
        position: absolute;
        left: 0;
        width: 100%;
        transform-origin: 0 0;
        border-bottom: 1px solid currentColor;
        &:first-child {
            top: 0
        }
        ;
        &:last-child {
            bottom: 0
        }
        ;
    }
    .div-line {
        --offset: calc(var(--font-size-content) * 0.2);
        padding-bottom: var(--offset);
        margin-bottom: calc(-1*var(--offset));
        display: inline-block;
        overflow: hidden;
    }
}

.footer__section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5em var(--padding-section);
    font-size: var(--font-size-content);
    font-style: normal;
    .label {
        flex: 1 0 auto;
        @include font-sans(1);
        font-size: var(--font-size-label);
        text-transform: uppercase
    }
    >.title {
        @include font-serif-light(1);
    }
    a {
        @include basic-a();
        @include font-serif-light(1);
        color: currentColor;
        &:not(:last-of-type) {
            &::after {
                //content: '/';
                margin: 0 .2em;
            }
            margin-right:.4em;
        }
    }
}

.footer__caption {
    @include font-sans-light(1);
    font-size: var(--font-size-label);
    text-align: center;
    margin: 8em 0 8em;
    text-transform: uppercase;
    strong {
        @include font-sans(1);
    }
}

#Footer > nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    a {
        @include basic-a();
        @include font-sans-light(1);
        font-size: var(--font-size-legal);
        color: inherit;
        margin: 0 1em;
    }
}

@media (max-width: $smartphone) {
    #Footer {
        --font-size: var(--font-size-legal);
    }
    #Footer>nav {
        justify-content: space-evenly;
    }
    .footer__section {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .label {
            margin-bottom: .7em;
        }
        a {
            display: block;
            width: 100%;
            text-align: right;
            &:not(:last-of-type)::after {
                content: "";
                margin: 0;
            }
        }
    }
}
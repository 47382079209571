.block-quote {
    --font-size-quote: var(--font-size-xl);
    --font-size-name: var(--font-size-base);
    --color: var(--primary-color);
    @media (max-width: $smartphone) {
        --font-size-quote: var(--font-size-m);
        --font-size-name: var(--font-size-xs);
    }
    display: grid;
    padding: 0 var(--padding-general);
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap);
    margin: 0 0 var(--margin-section);
}

.block-quote {
    .quote,
    .name {
        position: relative;
        margin: 0;
    }
    .image {
        grid-column: 1 / 6;
        grid-row: 1 / 3;
        padding-top: calc(var(--font-size-h1) * .3);
        margin-bottom: calc(-5 * var(--font-size-quote));
        >figure>img {
            /*height: 150%;
            object-fit: cover;
            object-position: center center;*/
        }
    }
    .quote {
        grid-column: 5 / 12;
        grid-row: 3 / 4;
        @include font-serif-light(.9);
        font-size: var(--font-size-quote);
        text-transform: uppercase;
        letter-spacing: -.04em;
    }
    .name {
        grid-column: 5 / 12;
        grid-row: 4 / 5;
        @include font-sans();
        font-size: var(--font-size-name);
        align-self: flex-end;
        text-transform: uppercase;
        text-align: right;
        margin-top: 1em;
    }
    .div-line {
        --offset: calc(var(--font-size-quote) * 0.2);
        padding-top: var(--offset);
        margin-top: calc(-1*var(--offset));
        overflow: hidden;
    }
}

@media (max-width:$smartphone) {
    .block-quote {
        grid-template-rows: auto auto auto;
        .image {
            position: relative;
            grid-column: 3 / 11;
            grid-row: 1 / 2;
            margin-bottom: var(--gap);
        }
        .quote {
            grid-column: 1 / 13;
            grid-row: 2 / 3;
        }
        .name {
            grid-column: 1 / 13;
            grid-row: 3 / 4;
        }
    }
}
.block-cols {
    --grid-col-left: 2 / 7;
    --grid-col-right: 7 / 12;
    --padding-v: 10rem;
    --padding-h: var(--padding-general);
    --gap-cols: var(--padding-m);

    @media (max-width: $smartphone) {
        --grid-col-left: 1 / 13;
        --grid-col-right: 1 / 13;
        --padding-v: 5rem;
        --padding-h: var(--padding-s);
        --gap-cols: 1.5rem;
    }
}
.block-cols {    
    display: grid;
    gap: var(--gap-cols);
    grid-template-columns: repeat(12, 1fr);
    padding: var(--padding-v) var(--padding-h);
    margin: var(--margin-section) 0;

    > div:nth-child(1) {
        grid-column: var(--grid-col-left);
    }

    > div:nth-child(2) {
        grid-column: var(--grid-col-right);
    }

    p:first-of-type {
        text-indent: 3.2em;
    }
}

@media (max-width: $smartphone) {
    .block-cols {
        --font-size-h1: var(--font-size-mid);
        --font-size-h2: var(--font-size-mid);
        --font-size-h3: var(--font-size-mid);
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}
.block-press-list {
    --gap: var(--padding-m);

    color: var(--primary-color);
    padding: 0 var(--padding-general) var(--gap);

    @media (min-width: $smartphone) {        
        ul {
            display: grid;
            gap: var(--gap);
            grid-template-columns: repeat(3, 1fr);
            // grid-auto-rows: rem(200);
            grid-auto-rows: minmax(#{rem(250)}, auto);
            grid-auto-flow: row dense;
        }

       /* .h-1 { grid-row-end: span 1; }
        .h-2 { grid-row-end: span 2; }
        .h-3 { grid-row-end: span 3; }
        .h-4 { grid-row-end: span 4; }
        .h-5 { grid-row-end: span 5; }*/
        // .h-6 { grid-row-end: span 7; }
        // .h-7 { grid-row-end: span 8; }
    }
}

.widget-team {
    --font-size-name: var(--font-size-xl);
    --font-size-role: var(--font-size-s);
    --width-slide: 40vw;
    --width-img: 30vw;
    --width-slide-void: 40vw;

    @media (max-width:$smartphone) {
        --width-slide: 80vw;
        --width-img: 80vw;
        --width-slide-void: 0;
        --font-size-name: var(--font-size-l);
        --font-size-role: var(--font-size-s);
    }

    margin: var(--margin-section) 0;
}

.widget-team__slider {
    position: relative;
    width: 100vw;
    //height: 60vw;
    user-select: none;

    > .holder {
        position: relative;
        top:0;
        left:0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        //height: 100%;
    }
}

.widget-team__slider__item.void {
    width: var(--width-slide-void);
    flex: 0 0 var(--width-void);
    margin-right: var(--grid);
}

.widget-team__slider__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    perspective: 100px;
    background: transparent;
    border: 0;
    margin: 0;
    flex: 0 0 var(--width-slide);
    width: var(--width-slide);
    padding:  var(--gap);
    

    > .image {
        @include z-index(-1);
        width: var(--width-img);
        
        figure.media-holder img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            width: 120%;
            object-fit: cover;
        }
    }
  
    > .caption {
        @include z-index(2);
        position: relative;

        .name {
            display: block;
            text-align: center;
            @include font-serif-light(1);
            font-size: var(--font-size-name);
            text-transform: uppercase;
            margin-top: -.5em;
        }

        .role {
            display: block;
            text-align: center;
            @include font-sans(1);
            font-size: var(--font-size-role);
            text-transform: uppercase;
        }
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--black);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--black);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--black);
}

.form {
    --font-size: var(--font-size-xl);
    --font-size-label: var(--font-size-s);
    // --font-size-cta: min(16px, var(--font-size-xxsmall));
    // --font-size-legal: min(16px, var(--font-size-xxsmall));
    --input-height: #{(100px / 16px) * 1rem};
    --line-correction: #{(25px / 16px) * 1rem};
    --icon-top: #{(35px / 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-m);
        // --font-size-cta: var(--font-size-xxxsmall);
        // --font-size-legal: var(--font-size-xxxxsmall);
        --input-height: #{(55px / 16px) * 1rem};
        --icon-top: #{(10px / 16px) * 1rem};
    }
}

.form {
    input,
    label,
    textarea,
    select,
    fieldset {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        -webkit-appearance: none;
    }

    input {
        height: var(--input-height);
        padding-bottom: var(--line-correction);
    }

    textarea {
        height: calc(3 * var(--input-height));
        max-height: calc(3 * var(--input-height));
        padding-bottom: var(--line-correction);
    }
    
    select {
        height: calc(var(--input-height) + var(--line-correction));
        margin-top: calc(-1 * var(--line-correction));
    }

    select,
    input,
    textarea {
        @include font-serif-light(1.4);

        background: transparent;
        font-size: var(--font-size);
        cursor: pointer;

        &:not([type='checkbox']) {
            // height: var(--input-height);
            // line-height: var(--input-height);
            width: 100%;
        }
    }

    label {
        @include font-sans();

        font-size: var(--font-size-label);
        margin-bottom: var(--padding-xs);
        text-transform: uppercase;
    }

    fieldset {
        margin-bottom: var(--padding-m);
        position: relative;

        .icon {
            height: rem(44);
            position: absolute;
            right: 0;
            top: var(--icon-top);
            opacity: 0;
            pointer-events: none;
            transition: .2s ease-out;
            width: rem(44);
            
            svg {
                height: 100%;
                width: 100%;
            }
        }

        &:not(.--checkbox)::before,
        &:not(.--checkbox)::after {
            bottom: 0;
            content: '';
            height: 1px;
            position: absolute;
            left: 0;
            width: 100%;
        }
        
        &:not(.--checkbox)::before {
            @include z-index($z-index-bg);

            background-color: var(--black);
            opacity: .5;
        }
        
        &:not(.--checkbox)::after {
            @include z-index($z-index-3D);

            background-color: var(--black);
            transform: scale3d(0, 1, 1) translateZ(#{$z-index-3D}px);
            transition: transform .3s ease-out;
            transform-origin: center left;
        }

        &.--focus,
        &.--success,
        &.--error {
            &:not(.--checkbox)::after {
                transform: scale3d(1, 1, 1);
            }
        }

        &.--success {
            border-color: var(--color-success);
        }

        &.--error {
            .icon {
                opacity: 1;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-start;

        &__footer {
            margin-top: var(--padding-xl);
        }
    }
}

.block-images-multi-column {
    display: flex;
    gap: var(--gap);
    padding: 0 var(--padding-general);
    margin: var(--margin-section) 0;
    justify-content: center;
    align-content: center;

    > div {
        flex: 1 0 auto;
    }

    figure.media-holder img {
        height: 120%;
        object-fit: cover;
        object-position: center;
    }
}

    
.default {
    position: relative;
    
    &:not(.home):not(.project) {
        padding-top: var(--header-height);
    }
    .__title>div {
        // perspective: 1000px;
    }
    //overflow: hidden;
}
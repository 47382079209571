.project-info {
    --font-size-p: var(--font-size-base);
    --color: var(--primary-color);
}

.project-info {
    display: grid;
    padding: 0 var(--padding-general);
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap);
    margin: var(--padding-l) 0 var(--margin-section) 0;

    > .title {
        grid-column: 7 / 13; 
        grid-row: 1 / 2;
        @include font-sans();
        margin: 0;  
        font-size: var(--font-size-p);
        text-transform: uppercase;
        text-align: right;

        > .h1 {
            margin: 0 0 0 auto;
            font-size: .3em;
            @include font-sans-light();
            max-width:  #{(240px / 16px) * 1rem};
        }
    }

    > .body {
        grid-column: 1 / 6;
        grid-row: 1 / 4;
    }

    figure.media-holder {
        grid-column: 7 / 13;
        grid-row: 3 / 4;
        margin-top: var(--padding-general);

        > img {
            height: 120%;
            object-fit: cover;
            object-position: center;
        }
    }
}

@media (max-width:$smartphone) {
    .project-info {
        > .title {
            grid-column: 1 / 13; 
            grid-row: 1 / 2;
            margin-bottom: 1em;
        }

        > .body {
            grid-column: 1 / 13;
            grid-row: 2 / 3;
        }

        figure.media-holder {
            grid-column: 1 / 13;
            grid-row: 3 / 4;
        }
    }
}

    
.block-video {
    --font-size: var(--font-size-xl);
    --color: var(--primary-color);
    --left-start-col: 4;
    --left-end-col: 12;

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-m);
        --left-start-col: 2;
        --left-end-col: 14;
    }
        
    display: grid;
    padding: var(--margin-section) 0;
    grid-template-columns: calc(var(--padding-general) - var(--gap)) repeat(12, 1fr) calc(var(--padding-general) - var(--gap));
    gap: var(--gap);
}

.block-video {
    > .text {
        @include font-serif-light(.9);
        grid-column: var(--left-start-col) / var(--left-end-col);
        grid-row: 1 / 2;
        font-size: var(--font-size);
        letter-spacing: -.04em;
        margin: 0 0 1em 0;
        * { margin: 0 }
        text-align: center;
    }

    > figure {
        position: relative;
        grid-column: 1 / 15;
        grid-row: 2 / 3;
        height: 100vh;
        background-color: var(--black);

        video {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }

        span,
        button {
            position: absolute;
            display: block;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background: transparent;
            border: 0;
            cursor: none;
        }

        &.--muted {
            .pause { display: none; }
            .play { display: none; }
            .muted { display: block; }
        }

        &.--pause {
            .pause { display: none; }
            .muted { display: none; }
            .play { display: block; }
        }
        
        .muted { display: none; }
        .pause { display: block; }
        .play { display: none; }
    }
}

.block-images-slider-vertical {
    --width: 100%;
    --height:60vw;
    --max-height:80vh;
    --gap: var(--padding-xs);

    width: var(--width);
    margin: var(--margin-section) 0;
    padding: 0;
    user-select: none;
    
    > figure {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;

        > img {
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}

.block-images-slider-vertical__slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    > .holder {
        order: 2;
        position: relative;
        height: var(--height);
        max-height: var(--max-height);
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--gap);
    }
}

.block-images-slider-vertical__item {
    position: relative;
    flex: 0 0 auto;
    width: auto;
    height: 100%;
    overflow: hidden;

    svg {
        height: 100%;
        width: auto;
    }

    img {
        position: absolute;
        top:0;
        left:0;
        width: 130%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.block-images-slider-vertical__controls {
    position: relative;
    top:0;
    left:0;
    width: 100%;
    height: auto;
    padding: var(--padding-xs);
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    margin-bottom: -2em;
    
    button {
        --opacity: 0;
        --pos: 0;
        --pos-hover: -.5em;
        --pos-arrow: 1em;
        --pos-arrow-hover: .5em;

        position: relative;
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size-xs);
        text-transform: uppercase;
        color: inherit;
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3em 3em 0;
        pointer-events: all;
        color: var(--primary-color);

        &:nth-child(1) {
            padding: 3em 3em 3em 0;
            --pos-hover: 2em;

            > .target {
                left: 0;
            }
        }

        &:nth-child(2) {
            padding: 3em 0 3em 3em;
            --pos-hover: -2em;

            > .target {
                right: 0;
            }
        }

        > .text {
            transform: translate3d(var(--pos), 0, 0);
            transition: transform .4s ease-in-out;
        }

        > .target {
            position: absolute;
            top: 3em;
            pointer-events: none;
            opacity: var(--opacity);
            width: 1em;
            height: 1em;
            transition: opacity .2s ease-in;
            //clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

            /*> .text {
                position: absolute;
                top:0;
                left:0;
                transform: translate3d(0, var(--pos-arrow), 0);
                transition: transform .3s ease-in-out;
            }*/
        }

        &:hover {
            --opacity: 1;  
            --pos: var(--pos-hover); 
            --pos-arrow : var(--pos-arrow-hover);        
        }
    }
}
.footer-project {
    --font-size-label: var(--font-size-s);
    --font-size-content: var(--font-size-l);
    --font-size-legal: var(--font-size-s);
    --padding-section: var(--padding-general);
    --max-width-body: #{(500px / 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size-label: var(--font-size-xs);
        --font-size-content: var(--font-size-m);
        --font-size-legal: var(--font-size-xs);
        --padding-section: 0;
    }
}

.footer-project {
    padding: 0 var(--padding-general) 0;
    color: var(--primary-color);
    .separator {
        position: absolute;
        left: 0;
        width: 100%;
        transform-origin: 0 0;
        border-bottom: 1px solid currentColor;
        &:first-child {
            top: 0
        }
        ;
        &:last-child {
            bottom: 0
        }
        ;
    }
    .title {
        text-transform: uppercase;
    }
    .body {
        flex: 1 0 auto;
        max-width: var(--max-width-body);
        @include font-sans-light(1);
        font-size: var(--font-size-label);
        text-transform: uppercase;
        text-align: right;
        @media (max-width: $smartphone) {
            text-align: left;
            margin-top: 1em;
        }
    }
    .div-line {
        --offset: calc(var(--font-size-quote) * 0.2);
        padding-top: var(--offset);
        margin-top: calc(-1*var(--offset));
        overflow: hidden;
    }
}
@media (max-width:$smartphone) {
    .block-images-slider {
        --width: 100vw;
        height: 70vw;
        margin-left: 0;
        margin-right: 0;
    }

    .block-images-slider__controls {
        button {
            --opacity: 1;
            --pos: var(--pos-hover);

            > .text {
                opacity: 0;
            }
        }
    }
}
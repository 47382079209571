html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--green-dark);
    img {
        pointer-events: none;
    }
    main {
        width: 100%;
    }
    &.--home,
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }
    }
    &:not(.--home).__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        main {
            width: 100%;
            overflow-x: hidden;
        }
    }
}
.button {
    --bg: var(--primary-color);
    --color: var(--primary-bg);
    --padding-v: var(--padding-xsmall);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xsmall);
        --font-size: var(--font-size-base);
    }
}

.button {
    @include basic-a();
    @include font-sans-light();

    align-items: center;
    background: var(--bg);
    border: 1px solid var(--bg);
    color: var(--color);
    display: flex;
    font-size: var(--font-size);
    line-height: 1;
    padding: var(--padding-v) var(--padding-h);
    text-transform: uppercase;

    @include isCursor () {
        &:hover {
            background: var(--color);
            color: var(--bg);
    
             .icon {
                svg path {
                    fill: var(--bg);
                }
            }
        }
    }

    &--arrow {
        cursor: pointer !important;

        span:first-child {
            margin-right: var(--padding-xsmall);
        }

        .arrow {
            --rot: -45deg;
            --scale: .3;
            --pos: 100%;

            height: rem(25);
            position: relative;
            width: rem(25);
        }

        .box {
            background: var(--color);
            border: 1px solid var(--color);
            display: block;
            height: rem(25);
            transform: rotate(var(--rot)) scale(var(--scale));
            transition: transform .2s ease-out;
            width: rem(25);
        }

        .icon {
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            
            svg {
                height: 100%;
                position: absolute;
                right: var(--pos);
                top: 0;
                transition: right .2s ease-out;
                width: rem(20);

                path {
                    fill: var(--color);
                }
            }
        }

        @include isCursor () {
            &:hover .arrow {
                --scale: 1;
                --pos: 10%;
    
                .box {
                    background: transparent;
                    border: 1px solid var(--bg);
                    transition: transform .3s ease-out;
                }
    
                .icon {
                    svg {
                        transition: right .3s ease-out .1s;
                    }
                }
            }
        }
    }
}

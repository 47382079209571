:root {
    --white: #ffffff;
    --black: #000000;
    --grey: #999;

    --beige: #F5F1EC;

    --grey-lightest: #D5DBD8;
    --green-light: #A7B1AC;
    --green: #9AB1A5;
    --green-dark: #6A716D;
    --green-darkest: #2D3430;
    
    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #721515;
    --color-sucess: #4dc247;
}

/*.palette-green {
    --primary: var(--black);
    --secondary: var(--green);
}

.palette-green-light {
    --primary: var(--black);
    --secondary: var(--green-light);
}

.palette-green-lightest {
    --primary: var(--black);
    --secondary: var(--green-lightest);
}

.palette-green-dark {
    --primary: var(--white);
    --secondary: var(--green-dark);
}

.palette-green-darkest {
    --primary: var(--white);
    --secondary: var(--green-darkest);
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--white);
}

.palette-black {
    --primary: var(--white);
    --secondary: var(--black);
}

.palette-beige {
    --primary: var(--black);
    --secondary: var(--beige);
}*/

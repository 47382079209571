.widget-projects {
    --font-size-h1: var(--font-size-xs);
    --font-size-titles: calc(var(--font-size-xxxl) * .9);
    position: relative;
    width: 100vw;
    height: 100vh;
    
    * {
      user-select: none;
      -webkit-user-drag: none;
      user-select: none;

      * {
        user-select: none;
        -webkit-user-drag: none;
        user-select: none;
      }
    }

    > .bg {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }

    > .title {
      position: absolute;
      @include z-index(2);
      pointer-events: none;
      top: calc(50% - var(--font-size-titles) * .6);
      left: 0;
      width: 100%;
      text-align: center;
      @include font-sans-light();
      font-size: var(--font-size-h1);
      text-align: center;
      text-transform: uppercase;
    }

    > .title-aux {
      position: absolute;
      pointer-events: none;
      @include z-index(3);
      bottom: 0;//-.25em;
      left:0;
      width: 100%;
      white-space: nowrap;
      @include font-serif-light(1);
      font-size: var(--font-size-gigant);
      letter-spacing: var(--letter-spacing-serif);
      text-transform: uppercase;
      padding: 0 calc(var(--padding-general) - .04em);
    }
}

.widget-projects__titles {
  position: fixed;
  @include z-index(2);
  top:0;
  left:0;
  width: 100%;
  height: 100%;

  pointer-events: none;
  list-style: none;

  > li {
    position: absolute;
    top:50%;
    left:50%;
    white-space: nowrap;
    transform: translate3d(-50%,-50%,3px);

    @include font-serif-light(1);
    font-size: var(--font-size-titles);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-serif);
    display:none;
    &:nth-child(1) { display:block; }
  }
}

.widget-projects__images {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  
  &:active {
    cursor: grabbing;
  }

  .__cursor-custom & {
    pointer-events: none;
    cursor: none;

    &:active {
      cursor: none;
    }
  }

  * {
    user-select: none;
  }
}

.widget-projects__images__image {
  position: relative;
  pointer-events: none;
  
  a {
    position: relative;
    pointer-events: all;
    @include basic-a();
    display: block;
  } 

  .__cursor-custom & {
    a {
      cursor: none;
    }
  }

  position: relative;
  margin: 0 12vw;
  flex: 0 0 auto;
  width: 30vw;
  align-self: center;
  
  &.z1 { @include z-index(1); }
  &.z2 { @include z-index(3); }
  &.top {align-self: flex-start;}
  &.center {align-self: center;}
  &.bottom {align-self: flex-end;}
  &.first {margin-left: 40vw;}
  &.padding-top-1 { padding-top: 5vh; }
  &.padding-top-2 { padding-top: 10vh; }
  &.padding-top-3 { padding-top: 15vh; }
  &.padding-top-4 { padding-top: 0; }
  &.padding-bottom-1 { padding-bottom: 5vh; }
  &.padding-bottom-2 { padding-bottom: 10vh; }
  &.padding-bottom-3 { padding-bottom: 15vh; }
  &.padding-bottom-4 { padding-bottom: 0; }
  &.margin-top-1 { margin-top: 5vh; }
  &.margin-top-2 { margin-top: 10vh; }
  &.margin-top-3 { margin-top: 15vh; }
  &.margin-top-4 { margin-top: 0; }
  &.margin-bottom-1 { margin-bottom: 5vh; }
  &.margin-bottom-2 { margin-bottom: 10vh; }
  &.margin-bottom-3 { margin-bottom: 15vh; }
  &.margin-bottom-4 { margin-bottom: 0; }

  figure {
    overflow: visible;
    cursor: pointer;
    -webkit-user-drag: none;
    user-select: none;
    overflow: hidden;
    background-color: rgba($color: #FFFFFF, $alpha: .05);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
   
    img {
      width: 150% !important;
      height: 100% !important;
      pointer-events: none;
      object-position: center center;
      object-fit: cover;
    }
  }
}
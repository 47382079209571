.block-text-image-left-right {
    --font-size-title: var(--font-size-m);
    --font-size-text: var(--font-size-xl);
    --color: var(--primary-color);
    --margin-top: var(--padding-xxxl);
    --margin-bottom: var(--padding-xxxl);

    @media (max-width:$smartphone) {
        --font-size-title: var(--font-size-s);
        --font-size-text: var(--font-size-m);
        --margin-top: var(--padding-m);
        --margin-bottom: var(--padding-l);
    }
}

.block-text-image-left-right {
    margin: var(--margin-top) 0 var(--margin-bottom);

    &__wrapper {
        display: flex;
        padding: 0 var(--padding-general);
    }

    .title {
        @include font-sans-light();

        font-size: var(--font-size-title);
        margin-bottom: var(--padding-s);
        text-transform: uppercase;
    }

    .text {
        @include font-serif-light(1);

        font-size: var(--font-size-text);

        .div-line { overflow: hidden; }
    }

    .content { position: relative; }

    .image {
        > div {
            overflow: hidden;
        }

        .media-holder {
            transform: scale(1.3);
        }
    }

    @media (max-width:$smartphone) {
        &__wrapper {
            flex-direction: column;
        }

        .image {
            order: 2;
            align-self: flex-end;
            margin-top: calc(-1 * var(--padding-l));
            width: 65%;
        }

        .content {
            order: 1;
        }
    }

    @media (min-width:$smartphone) {
        &__wrapper {
            .left & {
                flex-direction: row-reverse;
            }
        }

        .content {
            flex: 0 1 56%;
            margin-left: calc(-1 * var(--padding-m));
            margin-top: var(--padding-xxl);
        }
        
        .image {
            flex: 0 1 44%;
            margin-top: var(--padding-xxxl);
        }

        .title {
            margin-bottom: var(--padding-xl);
        }
    }
}

:root {
    --font-sans: 'SweetSansProMedium', sans-serif;
    --font-sans-light: 'SweetSansProLight', sans-serif;
    
    --font-serif: 'PPEiko-Medium';
    --font-serif-light: 'PPEiko-Thin';

    --font-size-gigantl: #{(500px / 16px) * 1rem};
    --font-size-gigant: #{(335px / 16px) * 1rem};
    --font-size-gigantsm: #{(290px / 16px) * 1rem};
    --font-size-xxxl: #{(180px / 16px) * 1rem};
    --font-size-xxl: #{(150px / 16px) * 1rem};
    --font-size-xl: #{(80px / 16px) * 1rem};
    --font-size-l: #{(50px / 16px) * 1rem};
    --font-size-m: #{(37px / 16px) * 1rem};
    --font-size-base: #{(25px / 16px) * 1rem};
    --font-size-s: #{(15px / 16px) * 1rem};
    --font-size-xs: #{(12px / 16px) * 1rem};
    --font-size-xxs: #{(10px / 16px) * 1rem};
    
    --line-height-sans: 1;
    --line-height-serif: .85;
    --letter-spacing: -.06em;
    --letter-spacing-serif: -.06em;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        font-size: 16px;
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-light($line-height: 0) {
  font-family: var(--font-sans-light);
  font-weight: 300;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-serif($line-height:0) {
  font-family: var(--font-serif);
  font-weight: normal;
  @if $line-height > 0 { line-height: $line-height  }
  @else { line-height: var(--line-height-serif);  }
}

@mixin font-serif-light($line-height:0) {
  font-family: var(--font-serif-light);
  font-weight: 200;
  @if $line-height > 0 { line-height: $line-height  }
  @else { line-height: var(--line-height-serif);  }
}

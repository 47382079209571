.widget-projects-mobile {
  --width: 100vw;
  width: var(--width);
  height: 100vh;
  margin: 0;
  padding: 0;
  user-select: none;
  
  > figure {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;

      > img {
          height: 100%;
          object-fit: cover;
          object-position: center center;
      }
  }

  > .title {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: var(--padding-xs);
      @include font-sans();
      text-indent: 3.2em;
      font-size: var(--font-size-xs);
      color: var(--primary-color);
      text-transform: uppercase;

      br {
        display: none;
      }
  }
}

.widget-projects-mobile__slider {
  position: relative;
  width: 100%;
  height: 100%;
  
  > .holder {
      position: relative;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      background-color: var(--black);
  }
}

.widget-projects-mobile__item {
  position: relative;
  flex: 0 0 var(--width);
  width: var(--width);
  height: 100%;
  overflow: hidden;

  img {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--padding-s);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include font-serif-light();
    letter-spacing: var(--letter-spacing-serif);
    font-size: 13vw;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color);
    background-color: rgba($color: #000000, $alpha: .2);

    .small {
      @include font-sans();
      letter-spacing: 0;
      font-size: var(--font-size-xs);
      margin-top: 1em;
    }
  }
}

.widget-projects-mobile__controls {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  button {
      --opacity: 0;
      --pos: 0;
      --pos-hover: -.5em;
      --pos-arrow: 1em;
      --pos-arrow-hover: .5em;

      position: relative;
      @include basic-a();
      @include font-sans(1);
      font-size: var(--font-size-xs);
      text-transform: uppercase;
      color: inherit;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 3em 3em 0;
      pointer-events: all;
      color: var(--primary-color);

      &:nth-child(1) {
          padding: 3em 3em 3em var(--padding-xs);
          --pos-hover: 2em;

          > .target {
              left: 0;
          }
      }

      &:nth-child(2) {
          padding: 3em var(--padding-xs) 3em 3em;
          --pos-hover: -2em;

          > .target {
              right: 0;
          }
      }

      > .text {
          transform: translate3d(var(--pos), 0, 0);
          transition: transform .4s ease-in-out;
      }

      > .target {
          position: absolute;
          top: 3em;
          pointer-events: none;
          opacity: var(--opacity);
          width: 1em;
          height: 1em;
          transition: opacity .2s ease-in;
          //clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

          /*> .text {
              position: absolute;
              top:0;
              left:0;
              transform: translate3d(0, var(--pos-arrow), 0);
              transition: transform .3s ease-in-out;
          }*/
      }

      &:hover {
          --opacity: 1;  
          --pos: var(--pos-hover); 
          --pos-arrow : var(--pos-arrow-hover);        
      }
  }
}
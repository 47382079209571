.header-project {
    --font-size-title: var(--font-size-gigant);
    --font-size-headline: var(--font-size-l);
    --color: var(--white);

    --offset-bottom-title: calc(var(--font-size-title) * .25);
    --offset-left-title: 0;

    --height: calc(100vh + var(--offset-bottom-title));

    @media (max-width:$smartphone) {
        --font-size-title: 42vw;
        --font-size-headline: 32px;

        --height: calc(100 * var(--vh) + var(--offset-bottom-title));
    }    
}

.header-project {
    position: relative;
    width: 100vw;
    height: var(--height);
    color: var(--color);

    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr auto calc(var(--font-size-title) * .7) var(--offset-bottom-title);

    > figure {
        grid-column: 1 / 3;
        grid-row: 1 / 4;
        padding-top: 0;
        overflow: hidden;
        opacity: 0;
        
       &.media-holder > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            will-change: transform;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.2);
        }
    }

    > .title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > span {
            position: absolute;
            bottom: 0;
            left: var(--offset-left-title);
            display: block;
            white-space: nowrap;
            margin: 0;
            transform: translate3d(0, 100%, 0);

            @include font-serif-light(1);
            font-size: var(--font-size-title);
            letter-spacing: var(--letter-spacing-serif);
            text-transform: uppercase;
            padding: 0 calc(var(--padding-general) - .04em);
        }
    }

    > .headline {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        @include font-serif();
        font-size: var(--font-size-headline);
        letter-spacing: -.02em;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 0 calc(var(--padding-general) * 5);
        span {
            display: inline-block;
            opacity: 0;
        }
        span:not(:first-child) {
            margin-left: .2em;
        }
        span:first-of-type:before { content: '“'; }
        span:last-of-type:after { content: '”'; margin-left: -.2em;}

        @media (max-width:$smartphone) {
            grid-column: 1 / 3;
            padding: 0 var(--padding-general);
        }
    }

    > .date {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        @include font-sans();
        text-transform: uppercase;
        padding: 0 var(--padding-general);
        overflow: hidden;

        > span {
            display: block;
            position: relative;
            transform: translate3d(0, 95%, 0);
        }
    }

    > .counter {
        position: relative;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        @include font-sans();
        text-align: right;
        padding: 0 var(--padding-general);
        overflow: hidden;

        > span {
            display: block;
            position: relative;
            transform: translate3d(0, 95%, 0);
        }
    }
}

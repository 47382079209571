.block-project-list {
    --font-size-title: var(--font-size-m);
    --font-size-label: var(--font-size-s);
    --font-size-content: var(--font-size-xl);
    --font-size-text: var(--font-size-base);
    --padding-section: var(--padding-general);

    --size-date: #{(60px / 16px) * 1rem};
    --max-width-body: #{(200px / 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-s);
        --font-size-label: var(--font-size-xs);
        --font-size-content: var(--font-size-m);
        --font-size-text: var(--font-size-s);
        --padding-section: 0;
    }
}

.block-project-list {
    color: var(--primary-color);
    margin: var(--padding-l) 0;

    &__title {
        padding: 0 var(--padding-general);

        > * {
            @include font-sans-light();

            display: block;
            font-size: var(--font-size-title);
            padding: var(--padding-s) var(--padding-m);
            text-transform: uppercase;
        }
    }
    
    &__list {
        list-style: none;
        margin-bottom: var(--padding-xxl);
        position: relative;

        li {
            position: relative;
            padding: 0 var(--padding-general);
        }
        
        a {
            @include basic-a();
            
            color: currentColor;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: var(--padding-s) var(--padding-m);
            font-size: var(--font-size-content);
            text-transform: uppercase;
            font-style: normal;

            .separator {
                position: absolute;
                left: 0;
                width: 100%;
                transform-origin: 0 0;
                border-bottom: 1px solid currentColor;

                &:first-child { top: 0 }
                &:last-child { bottom: 0; }
            }

            .title {
                @include font-serif-light(1);

                flex: 1 0 auto;
                overflow: hidden;

                span {
                    display: block;
                    position: relative;
                }
            }

            .date {
                flex: 1 0 auto;
                max-width: var(--max-width-body);
                @include font-sans(1);
                font-size: var(--font-size-label);
                text-transform: uppercase;
                text-align: right;
            }

            .counter {
                flex: 0 0 var(--size-date);
                font-size: var(--font-size-label);
                @include font-sans(1);
            }
        }
    }

    &__info {
        @include z-index($z-index-3D);

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 var(--padding-general);
        position: relative;
        text-transform: uppercase;

        > div {
            @include font-sans-light();

            font-size: var(--font-size-text);
            max-width: rem(550);
        }
    }

    &__images {
        height: rem(390);
        margin: var(--padding-m) 0;
        overflow: hidden;
        padding-left: var(--padding-general);
        padding-right: var(--padding-general);
        position: relative;
        width: 100vw;

        > ul {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
        }
        
        li {
            overflow: hidden;
            
            &:not(:last-child) {
                margin-right: var(--padding-general);
            }
        }
        
        .media-holder {
            height: rem(390);
            padding-top: 0;
            transform: scale(1.4);
            width: auto;

            img {
                height: 100%;
                position: relative;
                width: auto;
            }
        }
    }

    @media (max-width: $smartphone) {
        &__title {
            > * {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__list a {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-left: 0;
            padding-right: 0;

            .counter {
                flex: 0 1 auto;
                order: 1;

                > span::after {
                    content: '·';
                    margin-right: rem(2);
                    opacity: 0;
                    transition: .2s ease-out;
                }

                > span.animated::after { opacity: 1; }
            }

            .date {
                order: 2;
                text-align: left;
            }

            .title {
                order: 3;
                margin-top: rem(4);
                width: 100%;
            }
        }

        &__info .button,
        &__container {
            display: none;
        }

        &__cta {
            display: flex;
            justify-content: flex-end;
            padding: 0 var(--padding-general);
        }

        &__images {
            height: rem(151);

            .media-holder {
                height: rem(151);
            }
        }
    }

    @media (min-width: $smartphone) {
        &__cta {
            display: none;
        }
    }
}

#images-holder {
    @include z-index($z-index-3D);

    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    
    .__cursor {
        position: absolute;
        top: 0;
        left: 55%;
        height: rem(330);
        width: rem(330);
    }
    
    .__holder {
        // background-color: var(--primary-color);
        opacity: 0;
        height: 100%;
        width: 100%;
        transform-origin: center center;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        position: relative;
        transform: translateY(-50%);
    }
    
    .media-holder {
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        top: 2px;
        left: 2px;
        position: absolute;
        padding: 0;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        
        img {
            height: 100%;
            object-fit: cover;
            opacity: 0;
        }
    }

    @media (max-width: $smartphone) {
        display: none;
    }
}

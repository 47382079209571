.block-default {
    --grid-col: 4 / 10;
    --padding-h: var(--padding-general);

    @media (max-width: $smartphone) {
        --grid-col: 1 / 13;
    }
}
.block-default {    
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 var(--padding-h) 10vh;

    > * {
        grid-column: var(--grid-col);
    }
}

@media (max-width: $smartphone) {
    .block-default {
        --font-size-h1: var(--font-size-mid);
        --font-size-h2: var(--font-size-mid);
        --font-size-h3: var(--font-size-mid);
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}
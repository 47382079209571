.next-project {
    --font-size-label: var(--font-size-base);
    --font-size-title: var(--font-size-xxxl);
    --padding-section: var(--padding-general);
    --color: var(--primary-color);
    @media (max-width: $smartphone) {
        --font-size-label: var(--font-size-s);
        --font-size-title: var(--font-size-l);
        --padding-section: var(--padding-general);
    }
}

.next-project {
    a {
        --opacity: 0;
        color: var(--primary-color);
        @media (max-width: $smartphone) {
            --opacity: 1;
            color: var(--white);
        }
        position: relative;
        @include basic-a();
        display: block;
        padding: var(--padding-general) var(--padding-general) calc(var(--font-size-title) + var(--padding-general));
        cursor: none;
        transition: color .2s ease-out;
        >figure.media-holder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            overflow: hidden;
            opacity: var(--opacity);
            transition: opacity .2s ease-out;
            img {
                height: 100%;
                object-fit: cover;
                object-position: center center;
                //transition: transform 10s ease-out;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .1);
            }
        }
        >.label {
            position: relative;
            padding-right: var(--padding-section);
            @include font-serif();
            font-size: var(--font-size-label);
            text-align: right;
            text-transform: uppercase;
            @media (max-width: $smartphone) {
                padding-right: 0;
            }
        }
        >.title {
            position: relative;
            @include font-serif-light(1);
            letter-spacing: var(--letter-spacing-serif);
            font-size: var(--font-size-title);
            text-transform: uppercase;
            text-align: right;
        }
        &:hover {
            color: var(--white);
            --opacity: 1;
            img {
                //transform: translate3d(5%, 0, 0) scale3d(1.1, 1.1, 1);
            }
        }
        .div-line {
            --offset: calc(var(--font-size-title) * 0.2);
            padding-top: var(--offset);
            margin-top: calc(-1*var(--offset));
            overflow: hidden;
        }
    }
}
.block-title-quote {
    --font-size-h1: var(--font-size-gigant);
    --font-size-quote: var(--font-size-xl);
    --font-size-p: var(--font-size-base);
    --color: var(--primary-color);
    --h1-align: center;

    &.--title-small {
        --font-size-h1: var(--font-size-l);
        --h1-align: right;
    }

    @media (max-width:$smartphone) {
        --font-size-h1: 23vw;
        --font-size-quote: var(--font-size-m);
        --font-size-p: var(--font-size-xxsmall);
    }

    display: grid;
    padding: 0 var(--padding-general);
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap);
    margin: 0 0 var(--margin-section);
}

.block-title-quote {
    .headline,
    .quote,
    .body {
        position: relative;
        margin: 0;
    }

    .image {
        grid-column: 4 / 13;
        grid-row: 2 / 3;
        padding-top: calc(var(--font-size-h1) * .3);
        margin-bottom: calc(-5 * var(--font-size-quote));
    }

    .headline {
        position: relative;
        grid-column: 1 / 13;
        grid-row: 1 / 4;
        text-align: var(--h1-align);
        @include font-serif-light(1);
        font-size: var(--font-size-h1);
        text-transform: uppercase;
        letter-spacing: var(--letter-spacing-serif);
        margin-top: -.1em;
        perspective: 1500px;
    }

    .quote {
        grid-column: 1 / 8;
        grid-row: 3 / 4;
        @include font-serif-light(.9);
        font-size: var(--font-size-quote);
        text-transform: uppercase;
        letter-spacing: -.04em;
    }

    .div-line {
        --offset: calc(var(--font-size-quote) * 0.2);
        padding-top: var(--offset);
        margin-top: calc(-1*var(--offset));
        overflow: hidden;
    }

    .div-image {
        perspective: 1000px;
    }

    .body {
        grid-column: 8 / 13;
        grid-row: 3 / 4;
        @include font-sans-light();
        font-size: var(--font-size-p);
        align-self: flex-end;
        p:first-of-type {
            text-indent: 3.2em;
        }
    }
}

@media (max-width:$smartphone) {
    .block-title-quote {
        .headline {
            display: flex;
            flex-wrap: nowrap;
            grid-row: 1 / 3;
        }

        .image {
            grid-column: 3 / 13;
            grid-row: 1 / 4;
        }

        .quote {
            grid-column: 1 / 13;
            grid-row: 5 / 6;

            &:first-child {
                margin-top: 0;
            }
            
            margin: 10vh 0;
        }

        .body {
            grid-column: 1 / 13;
            grid-row: 6 / 7;
        }
    }
}
.block-slider {
    --font-size-number: var(--font-size-gigantl);
    --font-size-title: var(--font-size-xl);
    --font-size-text: var(--font-size-base);
    --color: var(--primary-color);
    --padding-v: var(--padding-xl);
    
    @media (max-width:$smartphone) {
        --font-size-number: var(--font-size-xxxl);
        --font-size-title: var(--font-size-m);
        --font-size-text: var(--font-size-s);
        --padding-v: var(--padding-xl);
    }
}

.block-slider {
    color: var(--color);
    margin: var(--padding-v) 0;
    overflow: hidden;
    position: relative;
    width: 100vw;

    .counter {
        @include font-serif-light(1);
        font-size: var(--font-size-number);
    }
    
    .title {
        @include font-serif-light(1);
        font-size: var(--font-size-title);
    }

    .body {
        @include font-sans-light();
        font-size: var(--font-size-text);

        ul {
            margin-top: var(--padding-xsmall);
        }
        
        li {
            text-transform: uppercase;
            
            &:not(:last-child) {
                margin-bottom: var(--padding-xs);
            }
            
            &::before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                margin-right: var(--padding-xs);
                position: relative;
                pointer-events: none;
                top: -0.15em;
                width: 0.25em;
                height: 0.25em;
                background-color: currentColor;
            }
        }
    }
 
    .__touch & {
        padding: 0 var(--padding-general);

        .images-desktop {
            display: none;
        }

        .slide:not(:last-child) {
            margin-bottom: var(--padding-xl);
        }
        
        .counter {
            margin-top: calc(-1 * var(--padding-xxxl));
            position: relative;
        }
        
        .title {
            margin-bottom: var(--font-size-s);
        }
        
        .body {
            width: 75%;
            margin-left: 25%;
            
            ul {
                margin-top: var(--padding-xsmall);
            }
            
            li {
                text-transform: uppercase;
                
                &:not(:last-child) {
                    margin-bottom: var(--padding-xs);
                }
                
                &::before {
                    content: "";
                    display: inline-block;
                    border-radius: 50%;
                    margin-right: var(--padding-xs);
                    position: relative;
                    pointer-events: none;
                    top: -0.15em;
                    width: 0.25em;
                    height: 0.25em;
                    background-color: currentColor;
                }
            }
        }
    }



    .__cursor & {
        height: var(--height);

        > div {
            height: 100vh;
            position: relative;
            width: 100vw;
            overflow: hidden;
        }

        .holder {
            left: 0;
            position: absolute;
            top: 0;
            display: flex;
            height: 100%;
            width: auto;
        }

        .slide {
            padding: 0 var(--padding-general);
            width: 100vw;

            &:not(:first-child),
            &:not(:last-child) {
                margin-right: 20vw;
            }

            &:last-child {
                width: 10vw;
            }
        }

        .image { display: none; }

        .images-desktop {
            clip-path: polygon(1% 1%, 99% 1%, 99% 99%, 1% 99%);
            position: fixed;
            width: 70vw;
            left: 5vw;
            top: 10vh;
            overflow: hidden;
            height: 45vh;
            
            .media-holder {
                padding-top: 0;
                height: 100%;
                
                img {
                    object-fit: cover;
                    height: 100%;
                } 
            }
            
            .image {
                display: block;
                height: 100%;
                overflow: hidden;
                transform: translate3d(0, var(--progress), 0);
                transition: transform 1s var(--ease-out-quart);
                width: 100%;
            }
        }
        
        .header {
            bottom: 2vh;
            left: 0;
            position: absolute;
            width: 40vw;

            .title {
                margin-left: var(--padding-s);
            } 
        } 

        .body {
            bottom: 5vh;
            left: 55vw;
            position: absolute;
            width: 40vw;

            ul {
                margin-top: var(--padding-medium);
            }
            
            li {
                text-transform: uppercase;
                
                &:not(:last-child) {
                    margin-bottom: var(--padding-s);
                }
                
                &::before {
                    margin-right: var(--padding-xsmall);
                }
            }
        } 
    }
}

.block-images-text {
    --font-size-h1: var(--font-size-gigant);
    --font-size-quote: var(--font-size-l);
    --font-size-p: var(--font-size-base);
    --color: var(--primary-color);
    --left-start-col: 1;
    --left-end-col: 7;
    --right-start-col: 8;
    --right-end-col: 13;
    --col-left-0: 1;
    --col-left-1: 7;
    --col-right-0: 8;
    --col-right-1: 13;
    
    &.--right {
        --col-left-0: 7;
        --col-left-1: 13;
        --col-right-0: 1;
        --col-right-1: 6;
    }

    @media (max-width:$smartphone) {
        --font-size-h1: var(--font-size-l);
        --font-size-quote: var(--font-size-m);
        --font-size-p: var(--font-size-xxsmall);
        --col-left-0: 1;
        --col-left-1: 13;
        --col-right-0: 1;
        --col-right-1: 13;
    }
}

.block-images-text {
    display: grid;
    padding: var(--margin-section) var(--padding-general);
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap);

    @media (max-width:$smartphone) {
        display: block;
    }

    .left,
    .right {
        display: flex;
        flex-direction: column;
        grid-row: 1 / 2;
        >.image>figure>img {
            height: 125%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .left {
        grid-column: var(--col-left-0) / var(--col-left-1);
    }
    .right {
        grid-column: var(--col-right-0) / var(--col-right-1);
    }
    .left {
        align-self: flex-end;
        .image {
            order: 1;
        }
        .quote {
            order: 1;
        }
    }
    .right {
        align-self: flex-end;
        .image {
            order: 2;
        }
        .body {
            order: 1;
        }
    }
    .quote,
    .body {
        position: relative;
        margin: 0;
    }
    .quote {
        @include font-serif-light(.9);
        font-size: var(--font-size-quote);
        text-transform: uppercase;
        letter-spacing: -.04em;
        margin: 1em 0
    }
    .div-line {
        --offset: calc(var(--font-size-quote) * 0.2);
        padding-top: var(--offset);
        margin-top: calc(-1*var(--offset));
        overflow: hidden;
    }
    .body {
        @include font-sans-light();
        font-size: var(--font-size-p);
        align-self: flex-end;
        margin: 2em 0;
        p:first-of-type {
            text-indent: 3.2em;
        }
    }
    /* MODS */
    &.--left {
        .left {
            .quote {
                padding-left: var(--col-size);

                @media (max-width:$smartphone) {
                    padding-left: 0;
                }
            }
        }
    }
    &.--right {
        .left {
            .quote {
                padding-left: var(--col-size);

                @media (max-width:$smartphone) {
                    padding-left: 0;
                }
            }
        }
    }
}